const BANNER_SHOWN_FLAG = 'br_user_banner_shown'
const BRAZIL_MIN_TIMEZONE_OFFSET = 2 * 60;
const BRAZIL_MAX_TIMEZONE_OFFSET = 5 * 60;

export function initBrazilBanner() {
    if (!shouldShowBanner()) return

    const banner = document.getElementById('brazil-banner')
    const closeButtons = document.querySelectorAll('[data-brazil-banner="close"]')

    const closeBanner = () => {
        window.localStorage.setItem(BANNER_SHOWN_FLAG, 1)
        banner.hidden = true
        document.body.style.overflow = ''
    }

    banner.hidden = false
    document.body.style.overflow = 'hidden'

    closeButtons.forEach(button => {
        button.addEventListener('click', closeBanner, { once: true })
    })
}

export function correctBrazilLinkUrl() {
    const url = document.location.href
    const link = document.getElementById('brazil-banner-pass-link')

    let href = link.href

    if (/dev\d+/.test(url)) {
        const dev = url.match(/dev(\d+)/)[1];
        href = href.replace('https://mystudybay.com.br', `http://br${dev}.author24.local`)
    }
    if (/test\./.test(url)) href = href.replace('mystudybay', 'test.mystudybay')

    link.setAttribute('href', href)
}

function shouldShowBanner() {
    return (
        isProbablyBrazil() &&
        !window.localStorage.getItem(BANNER_SHOWN_FLAG)
    )
}

function isProbablyBrazil() {
    let hasBrazilLanguage = false

    for (const lang of window.navigator.languages) {
        const normalizedLang = lang.toLowerCase()
        if (
            normalizedLang === 'pt' ||
            normalizedLang === 'pt-br' ||
            normalizedLang === 'pt-pt'
        ) {
            hasBrazilLanguage = true
            break
        }
    }

    const timezoneOffset = Math.abs(new Date().getTimezoneOffset())
    const isInBrazilTimezone = (
        timezoneOffset >= BRAZIL_MIN_TIMEZONE_OFFSET &&
        timezoneOffset <= BRAZIL_MAX_TIMEZONE_OFFSET
    )

    return (
        isInBrazilTimezone &&
        hasBrazilLanguage
    )
}
