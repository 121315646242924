export const debounce = (fn, wait = 100) => {
    // this is double debounce
    // it fires like this
    // calls:          1111111111111111
    // actually fired: 1000000000000001

    // so only first and last call

    let shouldCallMore
    let timer
    let maxWaitTimer
    let isWaiting
    let fnArgs = []

    const stopWaiting = () => {
        if (shouldCallMore) fn(...fnArgs)
        isWaiting = shouldCallMore = false
    }

    const call = () => {
        shouldCallMore = false
        fn(...fnArgs)
    }

    return (...args) => {
        fnArgs = args

        shouldCallMore = true

        clearTimeout(timer)
        timer = setTimeout(stopWaiting, wait)

        if (isWaiting) return
        isWaiting = true

        clearTimeout(maxWaitTimer)
        maxWaitTimer = setTimeout(call, 20)
    }
}

export const isMobile = /Mobi|Android/i.test(navigator.userAgent)

export const preventScroll = () => {
    const initialPosition = document.documentElement.style.position
    const initialTop = document.documentElement.style.top
    const initialScrollY = window.scrollY
    document.documentElement.style.top = `-${initialScrollY}px`;
    document.documentElement.style.position = 'fixed';

    return () => {
        document.documentElement.style.position = initialPosition;
        document.documentElement.style.top = initialTop;
        window.scrollTo(0, initialScrollY)
    }
}

export const viewportSmall = `(max-width: 50rem)`
export const viewportMedium = `(max-width: 80rem) and (min-width: ${50 * parseFloat(getComputedStyle(document.documentElement).fontSize) + 1}px)`
export const viewportLarge = `(min-width: ${80 * parseFloat(getComputedStyle(document.documentElement).fontSize) + 1}px)`

export const watchMedia = (mqs, cb) => {
    const mq = window.matchMedia(mqs)
    const wrapperCb = e => {
        if (!e.matches) return
        cb()
    }

    if (mq.addEventListener) mq.addEventListener('change', wrapperCb)
    else if (mq.addListener) mq.addListener(wrapperCb)

    if (mq.matches) cb()
    return () => {
        if (mq.removeEventListener) mq.removeEventListener('change', wrapperCb)
        else if (mq.removeListener) mq.removeListener(wrapperCb)
    }
}

// is click outside the element
export const isClickOutside = (windowClickEvent, element) => {
    if (!windowClickEvent || !element) return

    const cursorX = windowClickEvent.clientX
    const cursorY = windowClickEvent.clientY

    // this probably happened because clicked with spacebar
    if (cursorX === 0 && cursorY === 0) return false

    const rect = element.getBoundingClientRect()
    const clickedOutsideX = cursorX < rect.left || cursorX > rect.right
    const clickedOutsideY = cursorY < rect.top || cursorY > rect.bottom

    return clickedOutsideX || clickedOutsideY
}

export const getRef = () => {
    const url = window.location.href
    const urlObj = new URL(url)

    let ref = urlObj.searchParams.get('ref')

    if (ref) document.cookie = `ref=${ref}; path=/`
    else if (
        document.referrer
        && document.referrer.length > 0
        && document.referrer.match(/^https?:\/\/([^\/]+\.)?(google|duckduckgo|bing)\.com(\/|$)/i)
    ) ref = '1d1999c8d5115a7b'

    return ref
}

export const saveRefToCookie = ref => {
    if (!ref) return
    document.cookie = `ref=${ref}; path=/`
}

export const attachRefsToStudybayLinks = (ref) => {
    const url = new URL(window.location.href)
    const shouldSetRef = (
        ref &&
        !(url.searchParams.has('ref') || url.searchParams.has('refid'))
    )
    if (shouldSetRef) url.searchParams.append('ref', ref)

    const urlSearch = url.search
    const trueLinks = document.querySelectorAll('a')
    const fakeLinks = document.querySelectorAll('span[data-secret]')
    const links = [...fakeLinks, ...trueLinks]
    links.forEach(link => {
        const href = link.href
        if (!href) return
        const url = new URL(href)
        const hostname = url.hostname
        const pathname = url.pathname
        const hash = url.hash

        const shouldNotUpdateHref = (
            !/(studybay\.|author24\.local)/.test(hostname) ||
            pathname === window.location.pathname ||
            hash
        )

        if (shouldNotUpdateHref) return

        link.setAttribute('href', `${link.href}${urlSearch}`)
    })
}

export const attachRefToRegForms = ref => {
    if (!ref) return

    const fastregForms = document.querySelectorAll('[action*="order/unregordershortform"], [action*="user/fastunregorder"]')
    fastregForms.forEach((form) => {
        let action = form.action

        // if ref is already set - return
        if (/(\?|\/|&)ref(id)?=[0-9a-zA-Z]+/.test(action)) return

        if (/\?.+&?/.test(action)) action = `${action}&ref=${ref}`
        else if (action.endsWith('/')) action = `${action}?ref=${ref}`
        else action = `${action}/?ref=${ref}`

        form.setAttribute('action', action)
    })
}

export const handleRef = () => {
    attachRefsToStudybayLinks()

    const ref = getRef()
    if (!ref) return
    attachRefToRegForms(ref)

    const fastregForms = document.querySelectorAll('[action*="order/unregordershortform"]')
    fastregForms.forEach(form => {
        form.addEventListener('submit', () => saveRefToCookie(ref))
    })
}

export const initSlider = root => {
    const isMobile = /Mobi|Android/i.test(navigator.userAgent)

    const hideElem = elem => elem.setAttribute('hidden', true)
    const showElem = elem => elem.removeAttribute('hidden')

    const start = () => {
        const slider = root.id === 'testimonials-slider' ? root.querySelector('.reviews__slider') : root.querySelector('.slider')
        const left = root.id === 'testimonials-slider' ? root.querySelector('.reviews__slider__control--left') :  root.querySelector('.slider__control--left')
        const right = root.id === 'testimonials-slider' ? root.querySelector('.reviews__slider__control--right') :  root.querySelector('.slider__control--right')
        const inner = root.id === 'testimonials-slider' ? root.querySelector('.reviews__slider__inner') :  root.querySelector('.slider__inner')

        let sliderWidth
        let innerWidth
        let scrollingAmount

        const scroll = amount => {
            const before = slider.scrollLeft
            slider.scrollBy(amount, 0)
            setTimeout(() => {
                const after = slider.scrollLeft
                if (before === after) {
                    slider.classList.remove(root.id === 'testimonials-slider' ? 'reviews__slider--snap' : 'slider--snap')
                    slider.scrollBy(amount, 0)
                    setTimeout(() => slider.classList.add(root.id === 'testimonials-slider' ? 'reviews__slider--snap' : 'slider--snap'), 100)
                }
            }, 30)
        }

        const scrollToLeft = () => scroll(-scrollingAmount)
        const scrollToRight = () => scroll(scrollingAmount)
        const getScrollingAmount = () => Math.ceil((sliderWidth / 3)) // scroll on one element

        const manageControls = debounce(() => {
            const scrollPos = Math.floor(slider.scrollLeft + sliderWidth)
            const isBeginning = Math.abs(scrollPos - sliderWidth) < 20
            const isEnd = Math.abs(scrollPos - innerWidth) < 20

            if (isEnd) hideElem(right)
            else showElem(right)

            if (isBeginning) hideElem(left)
            else showElem(left)
        })

        const fillSliderParams = debounce(() => {
            sliderWidth = Math.ceil(slider.getBoundingClientRect().width)
            innerWidth = Math.ceil(inner.getBoundingClientRect().width)
            scrollingAmount = getScrollingAmount()
            const noNeedToScroll = innerWidth <= sliderWidth

            if (noNeedToScroll) {
                hideElem(left)
                hideElem(right)
                slider.removeEventListener('scroll', manageControls)
                left.removeEventListener('click', scrollToLeft)
                right.removeEventListener('click', scrollToRight)
                return
            }

            manageControls()

            slider.addEventListener('scroll', manageControls)
            left.addEventListener('click', scrollToLeft)
            right.addEventListener('click', scrollToRight)
        })

        window.addEventListener('resize', fillSliderParams)
        fillSliderParams()

        return () => {
            window.removeEventListener('resize', fillSliderParams)
            slider.removeEventListener('scroll', manageControls)
            left.removeEventListener('click', scrollToLeft)
            right.removeEventListener('click', scrollToRight)
        }
    }

    const shouldhaveButtons = CSS.supports('scroll-behavior', 'smooth') || !isMobile || root.querySelector('.slider')
    if (shouldhaveButtons) return start()
}

export const initAccordion = (accordionRoot) => {

    const accordionBlocks = accordionRoot.querySelectorAll('.accordion');

    accordionBlocks.forEach(accordionBlock => {
        const controller = accordionBlock.querySelector('.accordion__controller');

        controller.addEventListener('click', () => {
            const button = controller.querySelector('.accordion__expander');
            const content = accordionBlock.querySelector('.accordion__content');

            const shouldShowContent= button.getAttribute('aria-expanded') === 'false';

            button.setAttribute('aria-expanded', shouldShowContent);
            if (shouldShowContent) content.removeAttribute('hidden');
            else content.setAttribute('hidden', true);

            button.focus();
        });
    })
}

export const initAutoSlider = ({slider, changeTime=3000}={}) => {
    const items = Array.from(slider.querySelectorAll('.auto-slider__item'))
    const itemsNumber = items.length
    let screenRightBorder = slider.offsetWidth
    let screenMiddle = screenRightBorder / 2
    let centeredIndex = 0

    const firstItem = items[0]
    const lastItem = items[itemsNumber - 1]

    const slide = () => {
        const sliderOffset = slider.getBoundingClientRect().left
        const leftPadding = screenMiddle - firstItem.getBoundingClientRect().width / 2
        const rightPadding = screenMiddle - lastItem.getBoundingClientRect().width / 2

        slider.style.paddingLeft = `${leftPadding}px`
        slider.style.paddingRight = `${rightPadding}px`

        screenRightBorder = slider.offsetWidth
        screenMiddle = screenRightBorder / 2

        const itemsExtendedInfo = items.map((item) => {
            const { width } = item.getBoundingClientRect()
            item.outerWidth
            return {
                offsetLeft: item.offsetLeft,
                width,
            }
        })

        if (centeredIndex >= itemsNumber) centeredIndex = 0

        const itemToCenter = itemsExtendedInfo[centeredIndex]
        const halfOfItemToCenter = itemToCenter.width / 2
        const moveTo = itemToCenter.offsetLeft + halfOfItemToCenter - screenMiddle - sliderOffset

        items.forEach((item, index) => {
            if (index === centeredIndex) item.classList.add('auto-slider__item--active')
            else item.classList.remove('auto-slider__item--active')
        })

        slider.scroll({left: moveTo, behavior: 'smooth'})
        centeredIndex++
    }

    slide()
    setInterval(slide, changeTime)
}

export function revealLinksOnAction(onLinksReady) {

    const linksLikeItems = document.querySelectorAll('span[data-secret]')

    const makeALink = (linkLikeItem) => {
        const attrs = linkLikeItem.attributes
        const html = linkLikeItem.innerHTML
        const link = document.createElement('a')

        for (const attr of attrs) link.setAttribute(attr.name, attr.value)
        link.setAttribute('href', link.dataset.secret.replace('*', 'https://').replace(/%/g, '.').replaceAll('$', '/'))

        if (linkLikeItem.classList.contains("dmca-badge")) {
            let currentUrl = window.location.href;
            if (!currentUrl.endsWith('/')) currentUrl += '/';
            link.href += currentUrl;
        }

        link.innerHTML = html
        linkLikeItem.replaceWith(link)
    }

    const reveal = () => {
        document.body.removeEventListener('touchmove', reveal)
        document.body.removeEventListener('click', reveal)
        document.body.removeEventListener('keydown', reveal)
        document.body.removeEventListener('mousemove', reveal)

        linksLikeItems.forEach(makeALink)
        onLinksReady?.()
    }

    document.body.addEventListener('touchmove', reveal)
    document.body.addEventListener('click', reveal)
    document.body.addEventListener('keydown', reveal)
    document.body.addEventListener('mousemove', reveal)
}


export const initializeIntercom = () => {
    if (isMobile) return
    const s = document.createElement('script')
    s.src = 'https://widget.intercom.io/widget/gg6lv3nt'

    function init() {
        const intercomSettings = {
            app_id: 'gg6lv3nt',
            horizontal_padding: 80,
            vertical_padding: 72,
            custom_launcher_selector: '.custom-intercom-launcher',
        };
        Intercom('boot', intercomSettings)

        const button = document.querySelector('.custom-intercom-launcher')
        if (button) button.removeAttribute('hidden')
    }

    if (s.attachEvent) {
        s.attachEvent('onload', init)
    } else {
        s.addEventListener('load', init, false)
    }
    // wait to prevent PageSpeed downscore
    setTimeout(() => {
        document.body.appendChild(s)
    }, 5000)
}

export const videoLazyLoad = ({root, videoElement, videoSrc, callback, rootMargin} ) => {
    const source = videoElement.querySelector('source')

    if (!window.IntersectionObserver) {
        source.src = videoSrc
        if (typeof callback !== 'function') return
        return callback(videoElement)
    }

    const onVideoLoaded = () => {
        videoElement.removeEventListener('loadeddata', onVideoLoaded)
        if (typeof callback !== 'function') return
        callback(videoElement)
    }

    const observerFunction = (entries, observer) => {
        entries.forEach((video) => {
            if (!video.isIntersecting) return
            source.src = videoSrc
            video.target.load();
            video.target.addEventListener('loadeddata', onVideoLoaded)
            observer.unobserve(video.target);
        });
    }

    const observerOptions = {}

    if (root) observerOptions.root = root
    if (rootMargin) observerOptions.rootMargin = rootMargin

    const videoObserver = new window.IntersectionObserver(observerFunction, observerOptions)
    videoObserver.observe(videoElement)
}

export const videoLaunchHandler = {
    isInitialized: false,
    videos: [],
    isHandlingTime: true,

    register(videoElement) {
        this.init()
        this.videos.push(videoElement)
    },

    isVideoInView(videoElement, windowHeight) {
        const coordinates = videoElement.getBoundingClientRect()
        return (
            coordinates.top < windowHeight && coordinates.top > 0 ||
            coordinates.bottom < windowHeight && coordinates.bottom > 0
        )
    },

    handle() {
        if (!this.isHandlingTime) return

        this.isHandlingTime = false

        setTimeout(() => {
            const windowHeight = window.innerHeight || document.documentElement.clientHeight

            this.videos.forEach(video => {
                const isVideoInView = this.isVideoInView(video, windowHeight)
                const shouldPlay = isVideoInView && video.paused
                const shouldStop = !isVideoInView && !video.paused
                if (shouldPlay) {
                    video
                        .play()
                        .then(function () {
                            return video.removeAttribute('controls');
                        })
                        .catch(function () {
                            return video.setAttribute('controls', true);
                        })
                }
                if (shouldStop) video.pause()
            })

            this.isHandlingTime = true
        }, 300)
    },

    init() {
        this.handle()
        if (this.isInitialized) return
        this.isInitialized = true
        window.addEventListener('scroll', this.handle.bind(this))
    }
}


export function autoplayInViewport(autoplayAttribute = 'data-autoplay-in-viewport') {
    const videos = document.querySelectorAll(`video[${autoplayAttribute}]`)

    videos.forEach(video => {
        const playButton = document.createElement('button')
        playButton.setAttribute('type', 'button')
        playButton.classList.add('IH')
        playButton.setAttribute('aria-hidden', true)
        playButton.addEventListener('click', () => video.play().catch(() => {
            video.setAttribute('controls', true)
        }), {
            once: true
        })
        document.body.appendChild(playButton)
        playButton.click()
    })

    videos.forEach(video => {
        videoLaunchHandler.register(video)
    })
}


export function revealOnAction(cb) {
    const reveal = () => {
        window.removeEventListener('scroll', reveal)
        document.body.removeEventListener('click', reveal)
        document.body.removeEventListener('keydown', reveal)
        document.body.removeEventListener('mousemove', reveal)

        cb()
    }

    window.addEventListener('scroll', reveal)
    document.body.addEventListener('click', reveal)
    document.body.addEventListener('keydown', reveal)
    document.body.addEventListener('mousemove', reveal)
}

// https://learn.javascript.ru/cookie
export function getCookie(name) {
    const matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([.$?*|{}()\[\]\\\/+^])/g, '\\$1') + "=([^;]*)"
    ))
    return matches ? decodeURIComponent(matches[1]) : undefined
}

export function setCookie(name, value = undefined, options = {}) {
    if (value === undefined) return

    options = {
        'path': '/',
        'max-age': 3600 * 24 * 30,
        ...options
    }

    let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value)

    for (const optionKey in options) {
        updatedCookie += "; " + optionKey
        const optionValue = options[optionKey]
        if (optionValue !== true) {
            updatedCookie += "=" + optionValue
        }
    }

    document.cookie = updatedCookie
}


export const setTimezone = () => {
    const timezoneInputs = document.querySelectorAll('[name="timezone"]')
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    timezoneInputs.forEach(input => input.value = timezone)
}
