import smoothscroll from 'smoothscroll-polyfill'
import header from '../_modules/header/index.js'
import { initBrazilBanner, correctBrazilLinkUrl } from '../_modules/brazil_banner/index'
import {
    initSlider,
    initAutoSlider,
    revealLinksOnAction,
} from '../_modules/helpers'
import { handleRefsUs, REFS_US } from '@studybay/common-client-library/ref-handle';
import { FirstTouchTracking } from '@studybay/common-client-library/first-touch-tracking';
import { sendDataLayerEvent } from '@studybay/common-client-library/analytics/dataLayer';
import { Amplitude as AMP } from '@studybay/common-client-library/analytics/amplitude.js';
import { Project } from '@studybay/common-client-library/constants.js';

// const Amplitude = new AMP(Project.sb);
// Amplitude.initAmplitude()
// Amplitude.trackAmplitudeEvent('SB-ORDER__PAGE-VIEW')

function setTimezone() {
    const timezoneInputs = document.querySelectorAll('[name="timezone"]')
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    timezoneInputs.forEach(input => input.value = timezone)
}

function handleAllAsOne(elements, cb) {
    elements.forEach(element => cb(element))
}

function decorateUrlWithTrackers(urlString) {
    var ga = window[window['GoogleAnalyticsObject']];
    var tracker;
    try {
        tracker = ga.getAll()[0];
        urlString = (new window.gaplugins.Linker(tracker)).decorate(urlString);
        // urlString = `${urlString}&deviceId=${Amplitude.getDeviceId()}`;
    } catch (error) {
        console.error('GA Error', error);
        return urlString;
    }
    return urlString;
}

function handleSignUpForms() {
    const shortRegForms = document.querySelectorAll('#sendUnregordershortform,#sendUnregordershortform-1,#sendUnregordershortform-2,#sendUnregordershortform-3')

    handleAllAsOne(shortRegForms, (element) => {
        element.addEventListener('submit', () => {
            const actionUrl = element.getAttribute('action')
            const newActionUrl = decorateUrlWithTrackers(actionUrl)
            element.setAttribute('action', newActionUrl)

            disableSubmitButtons()

            try {
                // Amplitude.trackAmplitudeEventViaBeacon('SB-ORDER__FORM-SUBMIT');
                sendDataLayerEvent('SB-ORDER__FORM-SUBMIT', {
                    'transport_type': 'beacon'
                });
            } catch (err) {
                console.error(`mr. BUGster is here! Can't send event tot the amplitude. That's the reason ${err}`, action)
            }
        })
        
    })

    function disableSubmitButtons() {
        handleAllAsOne(shortRegForms, (element) => {
            element.querySelector('[type="submit"]').setAttribute('disabled', true)
        })
    }
}


function init() {

    smoothscroll.polyfill()
    // For step form can't use jquery validation since we can't get back on steps !!!

    const step1Wrapper = document.querySelector('.form-step--1')
    const step1Button = step1Wrapper.querySelector('.form-step__btn')
    const step2Wrapper = document.querySelector('.form-step--2')
    const step2Button = step2Wrapper.querySelector('.form-step__btn')
    const step3Wrapper = document.querySelector('.form-step--3')
    const step3Button = step3Wrapper.querySelector('.form-step__btn')
    step1Button.addEventListener('click', () => {
        step1Wrapper.classList.remove('form-step--active')
        step2Wrapper.classList.add('form-step--active')
    })
    step2Button.addEventListener('click', () => {
        const input = step2Wrapper.querySelector('.input-text')
        if (!input.reportValidity()) return
        step2Wrapper.classList.remove('form-step--active')
        step3Wrapper.classList.add('form-step--active')
    })
    step3Button.addEventListener('click', (e) => {
        e.preventDefault()
        var email = step3Wrapper.querySelector('.input-text')
        if (!email.reportValidity()) return
        document.querySelector('.form-steps').submit();
    })

    const directRef = window.location.origin.includes('.app') ? REFS_US.MARKETING_US_PPC : REFS_US.SEO_US_DIRECT

    const shortRegForms = document.querySelectorAll('#sendUnregordershortform,#sendUnregordershortform-1,#sendUnregordershortform-2,#sendUnregordershortform-3')

    header()
    initBrazilBanner()
    revealLinksOnAction(() => {
        correctBrazilLinkUrl()
        handleRefsUs(REFS_US.SEO_US_ORDER, directRef)
        FirstTouchTracking()
    })
    handleSignUpForms()
    handlePromocode()
    handleSliders()
    setTimezone()
}

function handlePromocode() {
    // Promocode
    const promocodeButton = document.querySelector('.hero__have-promo-link')
    const promocodePresenseField = document.querySelector('.hero__have-promo-field')

    promocodeButton.addEventListener('click', () => {
        promocodeButton.hidden = true
        promocodePresenseField.classList.add('hero__have-promo-field--active')
        promocodePresenseField.hidden = false
    })

    const promocodeInput = document.getElementById('promocode-input')
    const checkPromocodeBtn = document.querySelector('.hero__have-promo-field-btn')
    const wrongPromocode = document.querySelector('.hero__have-promo-field-no')
    const wrongPromocodeText = wrongPromocode.querySelector('.hero__have-promo-field-no__text')
    const correctPromocodeField = document.querySelector('.hero__have-promo-field-yes')
    const promocodeRegInput = document.querySelectorAll('[data-js="promocode"]')

    promocodeInput.addEventListener('focus', () => {
        checkPromocodeBtn.hidden = false
        wrongPromocode.hidden = true
    })

    wrongPromocode.addEventListener('click', () => {
        wrongPromocode.hidden = true
        checkPromocodeBtn.hidden = false
    })

    checkPromocodeBtn.addEventListener('click', async () => {
        var promocode = promocodeInput.value

        if (promocode.length < 1) {
            checkPromocodeBtn.hidden = true;
            wrongPromocodeText.innerHTML = 'Invalid promo code'
            wrongPromocode.style.display = 'flex';
            wrongPromocode.hidden = false
        } else {
            const form = new FormData()
            form.append('code', promocode)

            const rawData = await fetch('/ajax/checkPromocodeAll', {
                headers: {'x-requested-with': 'XMLHttpRequest'},
                body: form,
                method: 'post'
            })

            const data = await rawData.json()
            const status = data.status;
            if (status === 1) {
                correctPromocodeField.innerHTML = `
                    Success! You get ${data.sum}
                    <img src="/assets/img/outerPages/orderPage/sprite.svg#check" alt="success">
                `
                correctPromocodeField.style.display = 'flex'
                correctPromocodeField.hidden = false
                checkPromocodeBtn.hidden = true
                wrongPromocode.hidden = true
                promocodeInput.disabled = true

                handleAllAsOne(promocodeRegInput, (element) => element.value = promocode)

                document.querySelector('[data-js="promocodeSum"]').innerHTML = data.sum;
            }
            if (status === -1 || status === -2) {
                wrongPromocodeText.innerHTML = 'Invalid promo code'
                wrongPromocode.style.display = 'flex'
                wrongPromocode.hidden = false
                checkPromocodeBtn.hidden = true;
            }
            if (status === -3) {
                wrongPromocodeText.innerHTML = 'Required field'
                wrongPromocode.style.display = 'flex'
                wrongPromocode.hidden = false
                checkPromocodeBtn.hidden = true
            }
        }
    })
}

function handleSliders() {
    const reviewsSlider = document.getElementById('reviews-slider')
    const expertsSlider = document.getElementById('experts-slider')
    const whySlider = document.getElementById('why-slider')
    const popularSlider = document.getElementById('popular-slider')

    initSlider(reviewsSlider)
    initSlider(expertsSlider)
    initSlider(whySlider)
    initAutoSlider({
        slider: popularSlider,
        changeTime: 2000
    })
}

init()

