import {
  debounce,
  preventScroll,
  viewportSmall,
  viewportMedium,
  viewportLarge,
  watchMedia,
  isClickOutside,
  revealLinksOnAction,
} from '../helpers'
import { sendGtmWithRedirect } from '@studybay/common-client-library/analytics/dataLayer';

export default () => {
  const header = document.getElementById('page-header')
  if (!header) return
  if (header.dataset.noInit !== undefined) return
  const headerContainer = header.children[0];
  const headerBurgerOpen = document.getElementById('open-menu')
  const headerBurgerClose = document.getElementById('close-menu')

  const headerDropdownToggle = document.getElementById('header-dropdown-toggle')
  const headerDropdownDecoy = document.getElementById('header-dropdown-decoy')
  const headerDropdownContent = document.querySelector('#header-dropdown-toggle ~ [aria-labelledby="header-dropdown-toggle"]')

  const focustrapFirstMedium = document.querySelector('[data-focustrap-first*="m"]')
  const focustrapLastMedium = document.querySelector('[data-focustrap-last*="m"]')
  const focustrapFirstSmall = document.querySelector('[data-focustrap-first*="s"]')
  const focustrapLastSmall = document.querySelector('[data-focustrap-last*="s"]')

  const loginLink = document.querySelector('[data-login-link]')

  let focustrapFirst = null
  let focustrapLast = null
  let elementToFocusOnMenuOpening = null
  let restoreScroll = null

  //check header--invert
  let isHeaderInvert = header.classList.contains('header--invert');

  // turns dropdown into just regular content
  const destructurizeDropdown = () => headerDropdownContent?.removeAttribute('aria-expanded')

  // burger menu logic
  const openMenu = () => {
    header.setAttribute('aria-expanded', true)
    if (elementToFocusOnMenuOpening) elementToFocusOnMenuOpening.focus()
    restoreScroll = preventScroll()
  }

  const closeMenu = () => {
    header.setAttribute('aria-expanded', false)
    if (headerBurgerOpen) headerBurgerOpen.focus()
    if (restoreScroll) restoreScroll()
  }

  const openDropdown = () => {
    if (!headerDropdownContent) return;
    headerDropdownContent.setAttribute('aria-expanded', true)
    headerDropdownToggle.classList.add('expanded')
    if(isHeaderInvert) {
      headerDropdownDecoy.classList.add('dropdown__decoy-expanded')
      headerContainer.classList.add('header__container-expanded')
    }
    window.addEventListener('click', hideDropdownOnOutsideClick)
  }
    
  const closeDropdown = () => {
    if (!headerDropdownContent) return;
    headerDropdownContent.setAttribute('aria-expanded', false)
    headerDropdownToggle.classList.remove('expanded')
    if(isHeaderInvert) {
      headerDropdownDecoy.classList.remove('dropdown__decoy-expanded')
      headerContainer.classList.remove('header__container-expanded')
    }
    window.removeEventListener('click', hideDropdownOnOutsideClick)
  }

  const toggleDropdown = () => {
    const currentState = headerDropdownContent.getAttribute('aria-expanded') === 'true'
    const nextState = !currentState
    if (nextState === true) openDropdown()
    else closeDropdown()
  }

  const hideDropdownOnOutsideClick = (e) => {
    const clickedToggler = !isClickOutside(e, headerDropdownToggle)

    if (clickedToggler) return

    const clickedOutsideDropdown = isClickOutside(e, headerDropdownContent)
    const currentState = headerDropdownContent.getAttribute('aria-expanded') === 'true'

    if (clickedOutsideDropdown && currentState === true) closeDropdown()
  }

  // fires when header sticks to top
  const mobileAndTabletWidth = 1024
  const manageStickyHeader = debounce(e => {
    const becameSticky =
    e.target.documentElement.scrollTop > 0 ||
    document.documentElement.style.position === 'fixed'
    if (window.innerWidth > mobileAndTabletWidth) header.classList.toggle('header--sticky', becameSticky)
  }, 100)

  // needed for correct tab order in burger menu
  const recalculateTabindicies = size => {
      const elementsToInspect = Array.from(document.querySelectorAll('[data-tabindex]'))
      const model = elementsToInspect.map(elem => {
        const result = { elem }
        const tabIndiciesTuple = elem.dataset.tabindex.split(' ')
        if (tabIndiciesTuple[0] !== '0') result.tabIndexLarge = tabIndiciesTuple[0]
        if (tabIndiciesTuple[1] !== '0') result.tabIndexMedium = tabIndiciesTuple[1]
        if (tabIndiciesTuple[2] !== '0') result.tabIndexSmall = tabIndiciesTuple[2]
        return result
      })

      model.forEach(desc => {
          const elem = desc.elem
          elem.removeAttribute('tabindex')
          
          if (size === 'large' && desc.tabIndexLarge) {
            elem.setAttribute('tabindex', desc.tabIndexLarge)
            if (desc.tabIndexLarge === '1') elementToFocusOnMenuOpening = elem
          } else if (size === 'medium' && desc.tabIndexMedium) {
            elem.setAttribute('tabindex', desc.tabIndexMedium)
            if (desc.tabIndexMedium === '1') elementToFocusOnMenuOpening = elem
          } else if (size === 'small' && desc.tabIndexSmall) {
            elem.setAttribute('tabindex', desc.tabIndexSmall)
            if (desc.tabIndexSmall === '1') elementToFocusOnMenuOpening = elem
          }
      })
  }

  const getIsBurgerMenuOpened = () => header.getAttribute('aria-expanded') === 'true'

  const manageKeyDown = e => {
    const isTab = e.code === 'Tab' && !e.shiftKey
    const isShiftTab = e.code === 'Tab' && e.shiftKey
    const isEsc = e.code === 'Escape'

    const shouldPrevent = isTab || isShiftTab || isEsc

    const isBurgerMenuOpened = getIsBurgerMenuOpened()
    if (!isBurgerMenuOpened) return

    const isFirstElementInFocus = document.activeElement === focustrapFirst
    const isLastElementInFocus = document.activeElement === focustrapLast

    if (isEsc) closeMenu()
    else if (isTab && isLastElementInFocus) focustrapFirst.focus()
    else if (isShiftTab && isFirstElementInFocus) focustrapLast.focus()
    if (isEsc || isTab && isLastElementInFocus || isShiftTab && isFirstElementInFocus) e.preventDefault()
  }

  const sendLoginEvent = () => {
    sendGtmWithRedirect('Click_Login', '/login/', {
      'page_url': window.location.href,
    })
  }

  if (loginLink) loginLink.addEventListener('click', sendLoginEvent)

  if (headerDropdownToggle && headerDropdownContent) {
    headerDropdownToggle.addEventListener('click', toggleDropdown)
  }

  if (headerBurgerOpen && headerBurgerClose) {
    headerBurgerOpen.addEventListener('click', openMenu)
    headerBurgerClose.addEventListener('click', closeMenu)
  }

  // recalculate things on viewport width change
  const unwatchMQLarge = watchMedia(viewportLarge, () => {
    recalculateTabindicies('large')
    closeDropdown()
    closeMenu()
  })

  const unwatchMQMedium = watchMedia(viewportMedium, () => {
    destructurizeDropdown()
    recalculateTabindicies('medium')
    focustrapFirst = focustrapFirstMedium
    focustrapLast = focustrapLastMedium
  })

  const unwatchMQSmall = watchMedia(viewportSmall, () => {
    destructurizeDropdown()
    recalculateTabindicies('small')
    focustrapFirst = focustrapFirstSmall
    focustrapLast = focustrapLastSmall
  })

  window.addEventListener('scroll', manageStickyHeader)
  window.addEventListener('keydown', manageKeyDown)

  const shouldBecomeSticky = (
      document.documentElement.scrollTop > 0 ||
      document.documentElement.style.position === 'fixed'
  )
  if (shouldBecomeSticky) header.classList.toggle('header--sticky', shouldBecomeSticky)

  return () => {
    if (headerDropdownToggle) {
      headerDropdownToggle.removeEventListener('click', toggleDropdown)
    }

    if (loginLink) {
      loginLink.removeEventListener('click', sendLoginEvent)
    }

    if (headerBurgerOpen && headerBurgerClose) {
      headerBurgerOpen.removeEventListener('click', openMenu)
      headerBurgerClose.removeEventListener('click', closeMenu)
    }
    window.removeEventListener('scroll', manageStickyHeader)
    unwatchMQLarge()
    unwatchMQMedium()
    unwatchMQSmall()
  }
}
